<template>
  <div>
    <portal to="page-top-title">Api #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'apis-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/apis/${id}`" data-prop="api" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ api.name }}</column-info>
          <column-info title="Provider">
            <router-link :to="{ name: 'apis-providers-single', params: { id: api.provider.id } }">{{ api.provider.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ api.created_at }}</column-info>
          <column-info title="Updated At">{{ api.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="api.active"/>
          </column-info>
          <column-info title="Sending Rate">
            <badge title="TPS">{{ api.sending_rate }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'
import Btn from '@/views/components/simple/Btn'
import api from '@/modules/strubs/api'

export default {
  name: 'ApisSingle',
  metaInfo () {
    return { title: 'Api #' + this.id }
  },
  components: {
    BoxSingle,
    BadgeActive,
    Badge,
    Btn
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      api: api
    }
  }
}
</script>
